/* ================================
=Desktop
================================ */

.sub-menu.wpmenucart {
  display: none;
}

.minicart-open {
  .sub-menu.wpmenucart {
    display: block;
  }
}

.wpmenucart-contents {
  cursor: pointer;
}

#menu-mini-cart {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: right;
  position: relative;

  li {
    .sub-menu.wpmenucart {
      position: absolute;
      right: 0;
      background-color: #fff;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
      text-align: left;
      padding: 20px;

      li:last-child {
        a {
          color: $secondary-color;
          border: none;
        }
      }
    }

    a {
      color: #373135;
      line-height: 24px;
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 5px;

      strong {
        margin: 0 5px;
      }

      .wpmenucart-thumbnail {
        img {
          box-shadow: none;
        }
      }
    }

    .wpmenucart-contents {
      color: #fff;
      line-height: 46px;
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      padding: 0 20px;
      background-color: $secondary-color;
      transition: $transition;

      &:hover, &:focus {
        background-color: #ccb387;
        text-decoration: none;
      }
    }
  }
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {


} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 1024px) {

  .mini-cart {
    li {
      >ul.dropdown-menu-mini-cart {
        left: -150px;
      }
    }
  }


} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .mini-cart {
    display: none;
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {


}
