/* ================================
=Desktop
================================ */

.product-highlight {
  text-align: center;

  img {
    max-height: 400px;
  }

  .left {
    text-align: left;

    h3 {
      font-size: 36px;
      font-weight: 300;
    }

    p {
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .price {
      color: $red;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    a.btn {
      width: 60%;
      font-size: 16px;
    }
  }
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .product-highlight {
    display: none;
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

}
