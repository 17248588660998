/* ================================
=Desktop
================================ */

.instagram-wrapper {
  width: 100%;
  overflow: hidden;
}

#owl-demo {
  border-top: 8px solid $black;
  border-bottom: 8px solid $black;

  &:before {
    content: "Instagram";
    background-color: $black;
    color: $white;
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px 10px;
    text-align: center;
    position: absolute;
    top: -33px;
    left: 50%;
    width: 160px;
    margin-left: -80px;
  }
}

.apif-owl-demo .owl-item .apif-owl-silder, .apif-owl-demo .owl-item .apif-owl-silder figure, .apif-owl-silder figure .apif-featimg .example-image-link {
  height: 300px;
}

.apif-owl-silder figure .apif-featimg .example-image-link {
  height: 300px;
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  #owl-demo {
    display: none;
  }

} /* End of view */
