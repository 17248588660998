/* ================================
=Desktop
================================ */

section.slideshow {
  background-color: $black;
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height:500px;
  padding: 0;

  .swiper-container {
    width: 100%;
    height: 500px;
    cursor: -webkit-grab;

    .swiper-wrapper {
      .swiper-slide {
        background-size: cover;
        height: 500px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        text-align: center;

        .swiper-content {
          h3 {
            color: #fff;
            margin: 0;
            padding: 0;
            width: 100%;
            line-height: 40px;
            font-size: 55px;
            font-weight: $font-weight-bold;
            display: inline-block;
            position: relative;
            font-family: $font-family;
            position: relative;
            z-index: 2;
          }

          p {
            font-size: 22px;
            color: #f2f2f2;
            font-weight: $font-weight-regular;
            margin-top: 10px;
            line-height: 34px;
            width: 100%;
            position: relative;
            z-index: 2;
          }

          a {
            display: inline-block;
            position: relative;
            z-index: 2;
          }
        }

        &:before  {
          content: "";
          position: absolute;
          display: block;
          z-index: 1;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .swiper-pagination-bullet-active {
      background: $primary-color;
    }

    .swiper-button-prev, .swiper-container-rtl, .swiper-button-next, .swiper-button-disabled {
      color: #fff;
      background-image: none;
      font-size: 30px;
    }

    .swiper-button-next.swiper-button-disabled {
      background-image: none;
    }

  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  section.slideshow {
    height: 300px;

    .swiper-container {
      height: 300px;

      .swiper-wrapper {
        .swiper-slide {
          height: 300px;

          .swiper-content {
            h3 {
              margin: 0;
              padding: 0;
              line-height: 40px;
              font-size: 32px;
            }

            p {
              margin-top: 0px;
            }

            a {
              padding: 20px 35px;
              font-size: 12px;
              margin-top: 10px;
            }
          }

          &:before  {
            content: "";
            position: absolute;
            display: block;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .swiper-pagination-bullet-active {
        background: $primary-color;
      }

      .swiper-button-prev, .swiper-container-rtl, .swiper-button-next, .swiper-button-disabled {
        color: #fff;
        background-image: none;
        font-size: 30px;
      }

      .swiper-button-next.swiper-button-disabled {
        background-image: none;
      }

    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  section.slideshow {
    height: 250px;

    .swiper-container {
      height: 250px;

      .swiper-wrapper {
        .swiper-slide {
          height: 250px;

          .swiper-content {
            h3 {
              font-size: 28px;
            }

            p {
              display: none;
            }

            a {
              padding: 15px 30px;
              font-size: 12px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

} /* End of view */
