/* ================================
=Desktop
================================ */

section.image-fullwidth-one, section.image-fullwidth-two {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateX(-180deg);
  position: relative;
  height:400px;
  padding: 0;
  -webkit-backface-visibility: hidden;
  background-attachment: fixed;

  .picture-overlay {
    @include overlay;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    position: relative;
    z-index: 1;
    text-align: left;

    .inner-content {
      padding: 15px;
      text-align: center;

      h3 {
        color: #fff;
        font-size: 58px;
        margin: 0;
        padding: 0;
        line-height: 46px;
        width:100%;
        font-weight: $font-weight-bold;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        text-shadow: 2px 2px 8px #313131;
        text-align: center;
      }

      p {
        color: #fff;
        font-size: 26px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        line-height: 46px;
        width:100%;
        text-shadow: 2px 2px 8px #313131;
      }

      a.btn-ghost {
        padding: 17px 30px;
        font-size: 13px;

        &:hover {
          color: #fff;
        }
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}

section.image-fullwidth-two {
  height: 600px;

  .inner {
    height: 600px;
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  section.image-fullwidth-one, section.image-fullwidth-two {
    height: 300px;

    .inner {
      height: 300px;

      .inner-content {

        h3 {
          font-size: 32px;
          line-height: 36px;
        }

        p {
          font-size: 20px;
          line-height: 36px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  section.image-fullwidth-one, section.image-fullwidth-two {
    height: 300px;

    .inner {
      height: 300px;

      .inner-content {

        h3 {
          font-size: 28px;
          line-height: 30px;
          margin-bottom: 15px;
        }

        p {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }

}
