/* ================================
=Desktop
================================ */

/* ----- =Gallery ------ */

.woocommerce-product-gallery {
  border: 1px solid $line-color;

  a {
    img {
      width: 100%;
      max-height: 360px;
      object-fit: contain;
    }
  }

  .flex-control-nav {
    width: 100%;
    padding: 0;

    &.flex-control-thumbs {
    border-top: 1px solid $line-color;

      li {
        width: 25%;
        border-right: 1px solid $line-color;

        img {
          width: 100%;
        }
      }
    }
  }
}

.woocommerce, .woocommerce-page {
  img.pswp__img, img.pswp__img {
    background-color: $white;
  }
}

.onsale {
  display: none;
}

.woocommerce {
  div.product {
    div.images {
      .flex-control-thumbs {
        li {
          img {
            object-fit: contain;
            height: 140px;
          }
        }
      }

      .woocommerce-product-gallery__trigger {
        background-color: #fff;
        border: 3px solid $primary-color;
        border-radius: 0px;
        transition: $transition;
        z-index: 1;

        &:before {
          border: 2px solid $primary-color;
        }

        &:after {
          background-color: $primary-color;
        }

        &:hover {
          background-color: $primary-color;
          border: 3px solid $primary-color;

          &:before {
            border: 2px solid #fff;
            color: #fff;
          }

          &:after {
            background-color: #fff;
          }
        }
      }
    }
  }
}

/* ----- =Summary ------ */

.woocommerce {
  div.product {
    div.summary {
      width: 49%;
      background-color: #fff;
      padding: 30px;
      border: 1px solid #f1f1f1;

      &.entry-summary {
        h1.product_title{
          font-size: 24px;
          color: $h1-color;
          font-weight: 700;
          margin: 0;
        }

        p.price {
          margin: 20px 0;
          ins {
            text-decoration: none;
            font-size: 24px;
            font-weight: $font-weight-bold;

            span {
              font-size: 22px;
              color: $price-color;
            }
          }

          del {
            span {
              color: $darker-grey;
              font-weight: 400;
            }
          }
        }

        .variations {
          label {
            color: $label-color;
            font-size: 14px;
          }
          select {
            padding: 2px;
            font-size: 14px;
            color: $form-text-color;
          }
        }

        form {
          .quantity {
            width: 90px;
            margin-right: 0px;
            float: left;

            input {
              width: 80px;
              padding: 15px 10px;
              border: 1px solid $line-color;
            }
          }
        }

        .product_meta {
          margin-top: 10px;

          span {
            font-size: 14px;
          }
        }
      }

      &.entry-summary {
        .product_meta {
          >span {
            width: 100%;
            display: block;
            color: $paragraph-color;
            line-height: 26px;
          }
        }
      }
    }

    .woocommerce-product-rating {
      margin-top: 10px;

      a {
        font-size: 13px;
        text-decoration: none;
      }
    }
    .stock {
      margin-bottom: 15px;
      font-weight: 400;
      color: $red;
    }
  }
  .star-rating {
    span {
      color: $primary-color;
    }
    &:before {
      color: $primary-color;
    }
  }
}

.cart {
  button.button.alt {
    margin: 0;
    padding: 10px 30px;
    font-size: 14px;
    background-color: $primary-color;
    color: $white;

    &:hover {
      background-color: $secondary-color;
      border: 3px solid $secondary-color;
    }
  }
}

.woocommerce-product-details__short-description {
  margin-bottom: 25px;
}

/* ----- =Tabs ------ */

.woocommerce {
  div.product {
    .woocommerce-tabs {
      ul.tabs {
        margin: 0 0 -1px 0;
        padding: 0;

        li {
          border: 1px solid $line-color;
          border-bottom: 1px solid $line-color;
          background-color: #fff;
          display: inline-block;
          position: relative;
          z-index: 0;
          border-radius: 0;
          margin: 0;
          padding: 0;

          a {
            padding: 10px 30px;
            color: $secondary-color;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
          }

          &:before, &:after {
            display: none;
          }
        }

        li.active {
          background: $bg-color-light;
          color: $secondary-color;
          border: none;
          border-radius: 0px;
          padding: 0;
          z-index: 1;

          a {
            padding: 10px 30px;
            border: 2px solid $bg-color-light;
            color: $secondary-color;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
          }

          &:before, &:after {
            display: none;
          }
        }

        &:before {
          display: none;
        }
      }
      .panel {
        padding: 50px 60px;
        border:none;
        background-color: $bg-color-light;
        box-shadow: none;

        p {
          font-size: 14px;
          font-weight: $font-weight-light;
        }
      }
    }
  }

  table.shop_attributes {
    border: none;

    tr {
      td {
        border-bottom: 1px solid $line-color-hover;
        font-size: 14px;

        p {
          font-size: 14px;
        }
      }

      th {
        border-bottom: 1px solid $line-color-hover;
        font-size: 14px;
      }

      &:nth-child(even) td, &:nth-child(even) th {
        background: transparent;
      }
    }
  }

  /* Reviews */

  #reviews {
    p, label, span {
      font-size: 14px;
      color: $paragraph-color;
    }

    label {
      color: $label-color;
    }

    #comments {
      ol.commentlist {
        padding: 0;

        li {
          .comment-text {
            border: none;
            border-radius: 0px;
            background-color: $white;
          }
        }
      }
    }
  }

  .comment-form {
    .comment-form-author, .comment-form-email {
      label {
        width: 100%;
      }
      input {
        border: $white;
        padding: 5px 15px;
      }
    }
    .comment-form-email {
      input {
        margin-bottom: 15px;
        padding: 5px 15px;
      }
    }
  }

  #review_form {
    #respond {
      textarea {
        border: none;
      }
    }
  }
}

.woocommerce-tabs {
  clear: both;

  &.wc-tabs-wrapper {
    margin-bottom: 30px;

    ul.tabs {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      text-align: left;
      width: 100%;

      &.wc-tabs {
        margin: 0;
        padding: 0;

        li {
          background-color: #fff;
          display: inline-block;
          position: relative;
          z-index: 0;
          border-radius: 0px;
          margin: 0;
          padding: 10px 25px;

          &.active {
            background: #222;
            z-index: 2;
            border-bottom-color: #fff;
            color: #fff;

            a {
              color: #fff;
              font-size: 14px;
              font-weight: $font-weight-regular;
              padding: 20px;
              text-decoration: none;
            }
          }

          a {
            color: #000;
            padding: 20px;
            text-decoration: none;
            font-size: 14px;
            font-weight: $font-weight-regular;
          }

          &:before {
            display: none;
          }
        }
      }
    }

    .woocommerce-Tabs-panel {
      text-align: left;
      float: left;
      width: 100%;
      float: left;
      padding: 20px;
      border-radius: 0px;

      h2 {
        display: none;
      }
    }
  }
}

.woocommerce #reviews span {
  font-size: $font-size-text-regular;
  color: $paragraph-color;
}


/* Wishlist */

.woocommerce table.shop_table {
  border-radius: 0px;
}

.yith-wcwl-add-to-wishlist {
  float: left;
  margin: 10px 0 20px 0;

  p, span, a {
    font-size: $font-size-text-regular;
    font-weight: 300;
  }
}

h4.yith-wcwl-share-title {
  font-size: 14px;
  color: $paragraph-color;
}

.yith-wcwl-add-button a.add_to_wishlist {
  background-color: #fff;
  color: $primary-color;
  font-size: 13px;
  font-weight: 300;
  border: 1px solid $line-color;
  margin: 20px 0 0 0;
  padding: 10px 30px;
  border-radius: $border-radius;
  transition: $transition;

  &:hover {
    background-color: $primary-color;
    border-radius: 0px;
    color: #fff;
    font-size: 13px;
    border: 1px solid $primary-color;
    margin: 20px 0 0 0;
    padding: 10px 30px;
    text-decoration: none;
  }
}

.wishlist_table tr td {
  text-align: left;
}

/* Add-ons */

.product-addon {
  .addon-name {
    font-size: 16px;
  }

  p {
    &.form-row {
      margin: 0 0 0 1px;
      padding: 0;
    }

    input {
      float: left;
      margin: 8px 8px 0 0;
    }

    label {
      font-size: 14px;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
  }
}


/* ----- =Usp's ------ */

.usps-single {
  background-color: $bg-color-dark;
  padding: 20px 30px;
  margin-top: 10px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      font-size: $font-size-text-regular;
      font-weight: 300;
      line-height: 26px;
      color: $white;

      svg {
        font-family: "Font Awesome 5 Pro";
        font-size: 20px;
        display: inline-block;
        float: left;
        margin-right: 10px;
        color: $primary-color;
      }
    }
  }
}

/* ----- =Related products ------ */

.up-sells.upsells.products, .related.products {
  float: left;
  width: 100%;
}

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  /* Wishlist */

  .yith-wcwl-share {
    ul {
      padding-left: 0px;
    }
  }

}


/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .woocommerce {
    div.product {
      div.summary {
        width: 100%;
      }
    }
  }

  .cart {
    button.button.alt {
      margin: 0;
      padding: 10px 25px;
      font-size: 14px;
    }
  }

  /* Wishlist */

  .wishlist_table {
    display: table;

    tr, td, th {
      display: block;
      width: 100%;
      box-sizing:border-box; clear:both
    }

    th.product-name, th.product-price, th.product-stock-status, th.product-remove, th.product-thumbnail, th.product-add-to-cart {
      display: none;
    }

    td.product-name, td.product-price, td.product-stock-status {
      &:before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
        margin-right: 10px;
      }
    }

    a.remove.remove_from_wishlist {
      width: 100%;
    }
  }

  .woocommerce table.wishlist_table tr td.product-remove {
    border: none;
  }

  /* Wishlist */

   #ess-main-wrapper .ess-sidebar-share {
    bottom: 0;
    top: inherit;

    .ess-social-network-lists .ess-social-networks {
        float: left;
      }
  }

}
