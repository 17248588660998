/* ================================
=Desktop
================================ */

.discounts {
  padding: 90px 0;
  background-color: $bg-color-light;
  line-height: 40px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

  .discount-box-large, .discount-box-small {
    margin-bottom: 30px;

    a {
      display: block;
      position: relative;
      width: 100%;
      height: 250px;
      overflow: hidden;

      &:after {
        content: "";
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 75%);
        width: 100%;
        height: 0px;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: $transition;
      }

      p.large-text, p.small-text {
        font-family: "Raleway";
        position: absolute;
        left: 30px;
        bottom: 50px;
        z-index: 1;
        color: $white;
        margin: 0;
        font-size: 28px;
        font-weight: 600;
        transition: $transition;
        text-align: left;
        text-transform: uppercase;
        padding: 0 0 15px 0;
        -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
      }

      p.small-text {
        font-size: 16px;
        font-weight: 200;
        line-height: 16px;
        padding: 5px 0 0 0;
        display: block;
        opacity: 0;
        bottom: 45px;
        left: -200px;
        transition: $transition;
      }


      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        object-fit: cover;
        transition: $transition;
        z-index: 0;
      }

      &:hover {
        &:after {
          height: 120px;
        }
        p.small-text {
          opacity: 1;
          left: 30px;
        }
        img {
          transform: scale(1.2);
        }
      }
    }
  }

  .discount-box-small {
    margin: 0px;

    a {
      height: 250px;

      .caption {
        p.large-text {
          font-size: 20px;
        }
        p.small-text {
          opacity: 0;
          font-size: 12px;
        }
      }

      &:hover {
        p.small-text {
          opacity: 1;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

.discounts {
  .discount-box-small {
    a {
      p.large-text {
        font-size: 20px;
      }
      p.small-text {
        font-size: 12px;
      }
    }
  }
}

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  .discounts {
    .discount-box-small {
      a {
        p.large-text {
          font-size: 20px;
        }
        p.small-text {
          font-size: 12px;
          bottom: 30px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 769px) {

  .discounts {
    padding: 45px 0;

    .discount-box-large, .discount-box-small {
      a {
        height: 175px;

        p.large-text {
          font-size: 28px;
        }
        p.small-text {
          font-size: 14px;
        }
      }
    }
    .discount-box-small {
      margin: 0 0 30px 0;

      a {
        height: 175px;

        p.large-text {
          font-size: 28px;
        }
        p.small-text {
          font-size: 14px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

    .discounts {
      padding: 45px 0;

      .row {
        display: none;
      }

      .discount-box-large, .discount-box-small {
        margin: 0px;
        a {
          height: 175px;
          p {
            padding: 15px 25px;
            font-size: 16px;
          }
        }
      }
    }

} /* End of view */
