/* ================================
=Desktop
================================ */

.footer {
  bottom: 0;
  padding: 30px 0 0 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  background: none;
  background-color: $footer-bg-color-top;

  h3 {
    color: $primary-color;
    text-align: left;
    text-transform: uppercase;
    font-weight: $font-weight-regular;
    font-size: 14px;
    margin: 20px 0 10px 0;

    &:after {
      display: none;
    }
  }

  p {
    line-height: 30px;
    text-align: inherit;
    text-transform: uppercase;
    color: $white;
    font-weight: 300;
    font-size: 12px;
  }

  h4 {
    color: $primary-color;
  }

  .widget {
    padding: 0px;
    background-color: $footer-bg-color-top;
    width: 100%;
    float: left;

    h3 {
      font-size: 14px;
    }

    img {
      width: auto;
    }

    a.socials {
      width: 36px;
      height: 40px;
      line-height: 42px;
      float: left;
      display: inline-block;
      margin: -32px 10px 70px 0;

      img {
        width: 100%;
      }
    }
  }

  .container {
    text-align: left;

    .widget.widget_nav_menu {
      ul {
        li {
          a {
            color: $white;
            font-weight: 300;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 10px 0 40px 0;
      li {
        line-height: 24px;
        color: $footer-text-color-top;
      }
    }

    a {
      color: $footer-link-color-top;
      font-weight: 300;
      font-size: 12px;
      text-transform: uppercase;
      transition: $transition;

      &:hover {
        color: $footer-link-color-top-hover;
        text-decoration: none;
      }
    }
  }

  .footer-bottom {
    background-color: #fff;
    padding: 10px 0;
    height: 38px;

    p {
      float: right;
      line-height: 38px;
      font-size: 13px;
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {


} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  .footer {
    .container {
      .footer-col {
        height: 320px;

        &:last-child {
          margin-bottom: 50px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 769px) {

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: auto;
    line-height: 20px;
    text-align: center;

    .widget {
      width: 100%;
      display: block;
      float: left;
      margin: 0;
    }

    .container {
      text-align: center;

      .footer-col {
        height: auto;
      }

      .widget.widget_nav_menu {
        ul {
          li {
            a {
              color: $white;
              font-weight: 300;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      ul {
        margin: 0;
        padding: 10px 0 0px 0;
        li {
          line-height: 24px;
        }
      }

      a {
        font-size: 12px;
      }
    }

    .footer-bottom {
      height: auto;
      float: left;
      padding: 20px 0 10px 0;
      text-align: center;
      width: 100%;

      p {
        float: none;
        width: 100%;
      }
    }
  }

} /* End of view */


/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: auto;
    line-height: 20px;
    text-align: center;
    padding: 40px 0 0 0;

    .container {
      text-align: left;

      .footer-col {
       &:nth-child(2) {
         display: none;
       }
      }

      .center {
        div {
          margin: 30px 0;
        }
      }

      h4 {
        margin-top: 0px;
      }

      .right {
        text-align: center;
      }
    }

    .footer-bottom {
      height: auto;
      float: left;
      padding: 20px 0 10px 0;

      p {
        margin-top: 10px;
        line-height: 22px;
      }
    }
  }

} /* End of view */
