/* ================================
=Desktop
================================ */

/* ----- =Usp's ------ */

.usps-sidebar {
  background-color: $bg-color-dark;
  padding: 30px 30px;
  margin-bottom: 30px;

  h3 {
    color: $white;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      font-size: $font-size-text-regular;
      line-height: 26px;
      color: $white;

      i, svg {
        font-size: 20px;
        display: inline-block;
        float: left;
        margin-right: 10px;
        color: $primary-color;
      }
    }
  }
}

// News sidebar
.news-sidebar {
  float: left;
  width: 100%;

  h3 {
    background: $darkest-grey repeat scroll 0 0;
    line-height: 40px;
    font-size: 16px;
    color: #fff;
    padding: 10px 25px;
    margin: 0;
    text-transform: uppercase;
  }

  .news-sidebar-content {
    background-color: $lightest-grey;
    padding: 20px 25px;
    float: left;
    width: 100%;

    a.news-sidebar-item {
      display: block;
      border: none;
      padding: 0 0 20px 0;
      margin-bottom: 20px;
      text-align: left;
      position: relative;
      float: left;
      border-bottom: 1px solid $light-grey;

      &:last-child {
        margin-bottom: 0px;
        padding: 0;
        border-bottom: none;
      }

      .newsimage {
        max-width: 160px;
        width: 30%;
        height: 90px;
        margin-right: 15px;
        position: relative;
        overflow: hidden;
        display: block;
        float: left;

        &:before {
          @include overlay;
          background-color: rgba(0, 0, 0, 0.3);
        }

        img {
          height: 100%;
          max-width: 100%;
          object-fit: cover;
          position: relative;
          z-index: 0;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transition: ease 500ms;
          -webkit-backface-visibility: hidden;
        }
      }

      &:hover {
        text-decoration: none;

        .caption {
          h4 {
            background: transparent;
            color: $primary-color;
            text-decoration: none;
          }
        }

        img {
          transform: scale(1.2,1.2);
        }
      }

      .caption {
        background-color: $lightest-grey;
        padding: 0px;
        position: relative;
        z-index: 1;
        width: 50%;
        float: left;

        p {
          margin: 10px 0 0 0;
          font-size: 15px;
          color: $primary-color;
          font-weight: 600;
        }

        p.date {
          font-size: 14px;
          font-weight: 300;
          color: rgba(0,0,0,0.5);
          margin-top: 5px;
        }

        h4 {
          font-size: 16px;
          font-weight: 700;
          margin: 0px;
          color: $black;
        }
      }
    }
  }
}

/* ----- =Contact & customer service ------ */

.page {
  .widget {
    background-color: $lightest-grey;
    text-align: left;
    padding: 30px;
    margin-bottom: 30px;

    &.widget_nav_menu {
      ul {
        li {
          a {
            text-transform: uppercase;
            font-weight: 400;
            font-size: 13px;
            color: $darker-grey;

            &:hover {
              color: $primary-color;
              text-decoration: none;
            }
          }
        }
      }
    }

    h2 {
      text-align: left;
      font-size: 18px;

      &:after {
        display: none;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      line-height: 36px;

      li {
        font-size: 14px;

        &.current_page_item {
          a {
            color: $primary-color;
          }
        }
      }
    }
  }
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

}
