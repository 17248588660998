/* ================================
=Desktop
================================ */

/* ----- Login ----- */

.account {
  .u-columns {
    text-align: left;

    h2 {
      text-align: left;
      font-size: 24px;

      &:after {
        display: none;
      }
    }

    .woocommerce-form-login, .register {
      border-radius: 0px;
      margin-top: 20px;
      width: 100%;
    }

    .woocommerce-password-strength {
      margin-top: 10px;
    }
  }
}

.woocommerce-account {
  .woocommerce-MyAccount-navigation {
    width: 100%;

    h3 {
      text-align: left;
      font-size: 18px;
      margin: 0 0 20px 0;
      border-bottom: 1px solid #f1f1f1;
      padding: 0 0 10px 0;
    }

    ul {
      padding: 0;
      margin: 20px 0 0 0;
      background-color: $sidebar-bg-color;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0;

        a {
          background-color: $sidebar-bg-color;
          color: $sidebar-link-color;
          font-size: 13px;
          font-weight: 500;
          line-height: 28px;
          padding: 0px;
          display: inline-block;
          width: 100%;
          text-decoration: none;

          &:hover, &.is-active {
              text-decoration: underline;
          }
        }
      }
    }
  }
}

.account {
  h1 {
    text-align: left;
    margin: 0 0 20px 0;
    padding-bottom: 10px;
  }
}

.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register {
    border: 1px solid $line-color;
    border-radius: 0px;
}

legend {
    float: left;
    margin-top: 30px;
    padding-bottom: 10px;
}

fieldset {
    margin-bottom: 20px;
}

.woocommerce-form.woocommerce-form-login.login {
  .button {
    margin-top: 10px;
  }
}

 .woocommerce-MyAccount-navigation {
    margin-top: 0px;
}

.woocommerce-account {
  .woocommerce-MyAccount-content {
     margin-top: 0px;
     width: 100%;

     .ui-accordion-content-active {
      height: auto !important;
     }

     .ui-state-default {
      border: none;
      background: $lighter-grey;
      font-weight: $font-weight-bold;
      font-size: 14px;
      color: #454545;
      padding: 12px 12px;
      border: 1px solid $lighter-grey;
      border-radius: 0px;
     }

     .ui-accordion .ui-accordion-content, .ui-widget-content {
             border: 1px solid $lighter-grey;
     }
  }
}

.woocommerce-account .addresses .title .edit {
    float: right;
    padding: 5px 15px;
}

.woocommerce form.checkout_coupon, .woocommerce form.login, .woocommerce form.register {
    margin: 0 0 30px 0;
}

.woocommerce form.login {
  width: 50%;
  margin: 0 auto;
}

.woocommerce-error {
    li {
        a {
            margin: 0px !important;
        }
    }
}

.woocommerce-MyAccount-content {
  h3 {
    font-size: 20px;
    margin: 10px 0 15px 0;
  }

  .edit {
    font-size: 14px;
  }

  .edit-account {

    .form-row {
      padding: 0;
      margin-bottom: 15px;
    }

    .form-row-first, .form-row-last {
      width: 49%;
      padding: 0;
      display: inline-block;
    }

    .form-row-last {
      float: right;
    }

    legend {
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      display: block;
      float: left;
    }

    fieldset {
      .form-row-wide {
        width: 32%;
        display: inline-block;
        margin-right: 1%;
      }
    }

    label {
      line-height: 20px;
    }
  }
}


/* ================================
=Mobile (max. 768px)
================================ */
@media screen and (max-width: 768px) {
  .u-columns {
    padding: 0 20px;

    .u-column1, .u-column2 {
      margin-bottom: 30px;
    }
  }
}

/* ================================
=Mobile (max. 425px)
================================ */

.woocommerce-MyAccount-navigation {
  margin-bottom: 30px;
}
