/* ================================
=Desktop
================================ */

/* ----- Cart ----- */

.woocommerce a.remove svg {
  position: relative;
  font-size: 24px;
}

.woocommerce-cart-form {
  margin-top: 15px;
}

.woocommerce-cart table.cart img {
  width: 50px;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
  border: 1px solid $line-color;
  padding: 10px 10px 12px;
  width: auto;
}

.shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents {
  border-radius: 0px;
  font-size: 14px;

  .product-thumbnail {
    img {
      max-width: 75px;
    }
  }

  input.button {
    margin: 0;
    padding: 12px 15px;
  }

  input.input-text {
    padding: 7px;
    font-size: 14px;
    margin-right: 2px;
  }
}

.wc-proceed-to-checkout {
  a {
    float: right;
    padding: 20px 40px;
    font-size: 16px;
    margin-top: 5px;
  }
}

.cart_totals {
  font-size: 14px;
  border-radius: 0px;

  .shop_table.shop_table_responsive {
    border-radius: 0px;
  }

  h2 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 15px;

    &:after {
      display: none;
    }
  }
}

.shipping-calculator-form {
  padding: 0;
}

/* ----- Checkout ----- */

.woocommerce-checkout #payment ul.payment_methods li {
  padding: 20px;
}

.checkout {
  .container {
    max-width: 750px;

    h3 {
      font-size: 18px;

      &#ship-to-different-address {
        margin-left: 25px;
        font-size: 14px;
      }
    }
  }
}

.wpmc-tabs-wrapper .wpmc-tabs-list {
  padding: 0;
  position: relative;
  z-index: 1;

  .wpmc-tab-item {
    color: $black;
    font-weight: 600;

    &:before {
      border-bottom: 1px solid $primary-color;
    }

    &.current {
      margin-top: -1px;

      .wpmc-tab-text {
        font-weight: 600;
      }

      .wpmc-tab-number {
        border-radius: 0px;
        border-color: $primary-color;
        border: 3px solid $primary-color;
      }

      &:before {
        border-bottom-color: $primary-color;
        border-bottom: 3px solid $primary-color;
      }
    }

    .wpmc-tab-number {
      border-radius: 0px;
      border-color: $black;
      color: $primary-color;
      font-weight: 400;
      border: 1px solid $primary-color;
    }
  }
}

label.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
  .input-checkbox {
    margin-left: 0px;
  }
}

label.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox span {
  font-size: $font-size-text-regular;
}

.wpmc-steps-wrapper {
  margin-top: 20px;

  input.input-text, textarea.input-text {
    background-color: rgba(0,0,0,0.075);
    padding: 10px;
    border: none;
  }

  span.select2-selection.select2-selection--single {
    border-radius: 0px;
    border: none;
    background-color: rgba(0,0,0,0.075);
  }

  .shop_table.woocommerce-checkout-review-order-table {
    border-radius: 0px;
    font-size: 14px;
  }

  .woocomerce-form.woocommerce-form-login.login {
    border-radius: 0px;

    .button {
      margin-right: 10px;

      &:hover {
        margin-right: 10px;
        color: #fff;
        background-color: $black;
        text-decoration: none;
      }
    }
  }

  #add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment {
    border-radius: 0px;
    border: 1px solid $line-color;
    background-color: $form-bg-color;

    .button.alt {
      padding: 15px 40px;

      &:hover {
        padding: 15px 40px;
      }
    }
  }
}

.wpmc-nav-wrapper {
  margin-top: 20px;
}

.woocommerce-checkout #payment ul.payment_methods li {
  background-color: $white;
}

.woocommerce a.remove:hover {
    color: $red !important;
    background: $white;
}

/* --- Form fields --- */

.woocommerce form .form-row-first, .woocommerce-page form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-last {
  float: inherit;
  display: inline-block;
  width: 50%;
}

/* Selects */

.woocommerce form .form-row.woocommerce-validated .select2-container, .woocommerce form .form-row.woocommerce-validated input.input-text, .woocommerce form .form-row.woocommerce-validated select {
  height: 43px;
}

.wpmc-steps-wrapper span.select2-selection.select2-selection--single {
  height: 39px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
}

/* Inputs */

#billing_postcode_field, #shipping_postcode_field {
  width: 20%;
  display: inline-block;
}

#billing_city_field, #shipping_city_field {
  width: 80%;
  display: inline-block;
}

#billing_company_field, #billing_country_field, #billing_address_1_field, #billing_address_2_field, #billing_phone_field, #billing_email_field, #shipping_company_field, #shipping_country_field, #shipping_address_1_field, #shipping_address_2_field {
  width: 50%;
  display: inline-block;
}

#billing_country_field {
  label {
    line-height: 34px;
  }

  .select2-selection.select2-selection--single {
    height: 41px;
    line-height: 34px;
    margin-top: -5px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px;
  }
}

/* Coupon */

.woocommerce form.checkout_coupon {
  border-radius: 0px;

  input.button {
    margin-top: 5px;
  }
}

/* ----- Thank you page ----- */

.woocommerce-order {
  section {
    padding: 20px 0;
  }

  .woocommerce-info {
    padding: 20px;

    &:before {
      display: none;
    }
  }

  .woocommerce-customer-details {
    margin-bottom: 0px;
  }

  h2 {
    font-size: 24px;
    margin: 0 0 20px 0;
    text-align: left;

    &:after {
      display: none;
    }
  }

  p {
    margin: 0 0 20px 0;
  }
}

.woocommerce ul.order_details {
    margin: 30px 0;
    padding: 0;
    list-style: none;
}

.woocommerce table.shop_table, .woocommerce .woocommerce-customer-details address {
  border-radius: 0px;
}

.woocommerce form .form-row .input-checkbox {
  margin: 7px 8px 0 0;
}

label.woocommerce-form__label.woocommerce-form__label-for-checkbox.checkbox {
  span {
    position: relative;
    margin: 0 0 0 20px;
  }
  span.required {
    margin: 0;
  }
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {


} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {


} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  /* ----- Thank you page ----- */

  .woocommerce-order {
    padding: 20px 0;

    .woocommerce-column {
      margin-bottom: 30px;
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  /* ----- Cart ----- */

  .shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents {
    border-radius: 0px;
    font-size: 14px;

    input.button {
      width: 100% !important;
      margin-top: 10px;
    }

    .coupon {
      .input-text {
        width: 100%;
        margin: 10px 0 5px 0;
      }
    }
  }

  /* ----- Checkout ----- */

  .wpmc-nav-wrapper {
    text-align: center;

    .wpmc-footer-left,
    .wpmc-footer-right.wpmc-nav-buttons {
      float: inherit;
      text-align: center;
    }

    #wpmc-back-to-cart {
      margin-bottom: 10px;

    }

  }

  /* --- Form fields --- */

  .woocommerce form .form-row-first, .woocommerce-page form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-last {
    float: inherit;
    display: inline-block;
    width: 100%;
  }

  /* Inputs */

  #billing_company_field, #billing_country_field, #billing_address_1_field, #billing_address_2_field, #billing_phone_field, #billing_email_field, #shipping_company_field, #shipping_country_field, #shipping_address_1_field, #shipping_address_2_field {
    width: 100%;
    display: inline-block;
  }

}
