/* ================================
=Desktop
================================ */

/* Slideshow categories */

.category-slideshow {
  background-color: $white;

    .swiper-slide {
      padding: 0 15px;
    }

    ul {
      width: 100%;
      height: 225px;
      padding: 0;

      li.product-category {
        width: 100%;
        float: left;
        list-style-type: none;
        text-align: center;
        overflow: hidden;
        position: relative;
        border-bottom: 2px solid $black;

        .img-wrapper {
          img {
            width: 100%;
            height: 225px;
            object-fit: cover;
          }

          &:before  {
            content: "";
            position: absolute;
            display: block;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.15);
          }
        }

        h2 {
          width: 100%;
          padding: 20px 0;
          font-size: 16px;
          position: absolute;
          bottom: -62px;
          z-index: 1;

          .count {
            display: none;
          }
        }

        a {
          display: block;
          overflow: hidden;
          position: relative;

          h2 {
            color: $black;
            background: $white;
            border-left: 2px solid $black;
            border-right: 2px solid $black;
            transition: 0.2s linear;
            width: 100%;
            text-align: center;
            margin-right: 20px;

            &:after {
              display: none;
            }

            span {
              color: white;
              border: 2px solid #fff;
              padding: 12px 15px;
              font-size: 12px;
              text-transform: uppercase;
              border-radius: 30px;
              display: block;
              float: none;
              width: 140px;
              margin: 20px auto 0 auto;
              transition: $transition;

              &:hover {
                background-color: #fff;
                color: $primary-color;
              }
            }
          }

          &:hover {
            h2 {
              background: $primary-color;
              border-left: 2px solid $black;
              border-right: 2px solid $black;
              color: $white;
              bottom: 0px;
            }
          }
        }
      }
    }

    .swiper-button-prev {
      background-image: none;
      border: 2px solid rgba(255, 255, 255, 0.4);
      padding: 10px 20px 15px 20px;
      left: 15px;
      transition: $transition;

      svg {
        color: #fff;
        font-size: 20px;
        margin-left: -6px;
      }

      &:hover {
        border: 2px solid rgba(255, 255, 255, 1);
      }
    }

    .swiper-button-next {
    background-image: none;
    border: 2px solid rgba(255, 255, 255, 0.4);
    padding: 10px 20px 15px 20px;
    right: 15px;
    transition: $transition;

    svg {
      color: #fff;
      font-size: 20px;
      margin-left: -6px;
    }

    &:hover {
      border: 2px solid rgba(255, 255, 255, 1);
    }
  }

}

/* Categories */

.price span {
  color: #212121;
  font-weight: bold;
  font-size: 15px;
}




/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  section.slideshow {
    height: 300px;

    .swiper-container {
      height: 300px;

      .swiper-wrapper {
        .swiper-slide {
          height: 300px;

          .swiper-content {
            h3 {
              margin: 0;
              padding: 0;
              line-height: 40px;
              font-size: 32px;
            }

            p {
              margin-top: 0px;
            }

            a {
              padding: 20px 35px;
              font-size: 12px;
              margin-top: 10px;
            }
          }

          &:before  {
            content: "";
            position: absolute;
            display: block;
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .swiper-pagination-bullet-active {
        background: $primary-color;
      }

      .swiper-button-prev, .swiper-container-rtl, .swiper-button-next, .swiper-button-disabled {
        color: #fff;
        background-image: none;
        font-size: 30px;
      }

      .swiper-button-next.swiper-button-disabled {
        background-image: none;
      }

    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  section.slideshow {
    height: 250px;

    .swiper-container {
      height: 250px;

      .swiper-wrapper {
        .swiper-slide {
          height: 250px;

          .swiper-content {
            h3 {
              font-size: 28px;
            }

            p {
              display: none;
            }

            a {
              padding: 15px 30px;
              font-size: 12px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

} /* End of view */
