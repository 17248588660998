/* ================================
=Desktop
================================ */

// Facet styling

.facet-sorter {
  float: left;
  width: 100%;
  line-height: 50px;
}

.facet-pager {
  float: left;
  width: 100%;
  line-height: 50px;
  border-top: 1px solid $line-color;
  border-bottom: 1px solid $line-color;
  margin-bottom: 20px;

}

.facetwp-counts {
  float: left;
  font-size: 14px;
  color: $darker-grey;
  line-height: 50px;
  font-weight: $font-weight-light;
}

.facetwp-sort {
  text-align: center;
  line-height: 50px;

  .facetwp-sort-select {
    border: 1px solid $line-color;
    background-color: transparent;
    color: $form-text-color;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 13px;
  }
}

.facetwp-per-page {
  margin: 10px 0 0 0;
  position: relative;
  z-index: 3;

  .facetwp-per-page-select {
    border: 1px solid $line-color;
    background-color: transparent;
    color: $form-text-color;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 13px;
    float: right;
  }
}

.facetwp-pager {
  float: right;
  span {
    font-size: 14px;
    color: $darker-grey;
  }
  a {
    font-size: 14px;
    border: 1px solid $line-color;
    line-height: 30px;
    width: 30px;
    text-align: center;
    transition: $transition;

    &:hover {
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: $white;
      text-decoration: none;
    }

    &.active {
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: $white;
    }
  }
}

.category-image {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  margin: 15px 0;
}

.woocommerce {
  ul.products {
    margin: 0 0 -30px 0;

    li.product {
      margin-bottom: 40px;
      cursor: pointer;
      text-align: center;
      transition: $transition;

      &:hover {
        a {
          .imagewrapper {

              img {
                transform: scale(1.2, 1.2);
              }
          }

          span.onsale {
            font-size: 0px;
            border: none;
          }
        }

        .button {
          color: $secondary-color;
        }
      }

      a {
        display: block;

        .imagewrapper {
          position: relative;
          overflow: hidden;

          img {
            margin: 0;
            height: 220px;;
            object-fit: contain;
            position: relative;
            z-index: 0;
            border-radius: 0px;
            transition: $transition;
            image-rendering: crisp-edges;
          }
        }

        .woocommerce-loop-product__title, h2 {
          font-size: 15px;
          color: $h2-color;
          text-transform: inherit;
          font-weight: 300;
          padding: 20px 0 10px 0;
          min-height: 40px;

          &:after, &:before {
            display: none;
          }
        }

        &:hover {
          text-decoration: none;
        }

        span.onsale {
          position: absolute;
          z-index: 1;
          display: inline-block;
          top: 0px;
          left: 0px;
          right: auto;
          border-radius: 0px;
          margin: 0;
          font-size: 11px;
          padding: 0px 7px;
          background-color: $red;
          border: none;
          color: #fff;
          transition: $transition;
        }

        span.price {
          padding: 0 0 15px 0;

          span.woocommerce-Price-amount.amount {
            color: $price-color;
            font-size: 20px;

            span.woocommerce-Price-currencySymbol {
              color: $price-color;
              font-size: 20px;
            }
          }

          del {
            display: inline-block !important;
            color: #888;

            span.woocommerce-Price-amount.amount {
              color: #888;
              font-size: 16px;

              span.woocommerce-Price-currencySymbol {
                color: #888;
                font-size: 16px;
              }
            }

            span {
              color: #888;
              font-weight: $font-weight-light;
              font-size: 14px;
            }
          }

          ins {
            text-decoration: none;

            .woocommerce-Price-amount.amount {
              color: $price-color;
              font-size: 20px;
              margin-left: 10px;

              span {
                color: $price-color;
              }
            }
          }
        }

        &.button {
          padding: 0;
          margin: 0;
          font-size: 13px;
          color: $text-link-color;
          background-color: transparent;
          border: none;
          display: none;
        }
      }
    }
  }
}


//--- Product Filter ---//

.woocommerce {
  .widget_layered_nav_filters {
    ul {
      li {
        padding: 0 10px 0 0;

        a {
          &:before {
            content: "\f00d";
            font-family: fontawesome;
          }
        }
      }
    }
  }

  .woocommerce-widget-layered-nav-list {
    li {
      a {
        color: $black;
        font-size: 13px;
        font-weight: 400;
      }
      span {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  .product-filter {
    margin: 0;
    background-color: transparent;
    padding-right: 15px;


    section {
      padding: 0;

      h3 {
        text-transform: uppercase;
        border-bottom: 1px solid $lighter-grey;
        padding-bottom: 10px;
      }

      .facetwp-checkbox {
        background-size: 13px 13px;
        color: $paragraph-color;
        font-size: 14px;
        font-weight: $font-weight-light;
      }
    }

    h2.widget-title {
      text-align: left;
      font-size: 18px;
      margin: 0 0 20px 0;
      border-bottom: 1px solid $line-color;
      padding-bottom: 10px;

      &:after {
        display: none;
      }
    }

    .chosen {
      width: 100%;

      a {
        color: $paragraph-color;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .product-categories {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        a {
          color: $paragraph-color;
          font-size: 13px;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }

    .widget_price_filter {
      .ui-slider {
        .ui-slider-range {
          background-color: $primary-color;
        }

        .ui-slider-handle {
          background-color: $primary-color;
          border-radius: 0px;
          z-index: 1;
        }
      }
    }
  }

  .widget_price_filter {
    .price_slider_wrapper {
      .ui-widget-content {
        background-color: $black;
      }
    }

    .ui-slider-horizontal {
      height: 5px;
    }

    .price_slider_amount {
      .button {
        font-size: 12px;
      }

      .price_label {
        font-size: 14px;
        display: block;
        width: 100%;
        float: left;
        text-align: left;
        margin-top: 10px;

        .from, .to {
          font-weight: 700;
        }
      }
    }
  }
}

//--- Product ordening ---//

.woocommerce {
  .woocommerce-ordering {
    select {
      border: 1px solid $line-color;
      background-color: transparent;
      color: $form-text-color;
      padding: 5px 10px;
      margin-right: 5px;
      font-size: 13px;
    }
  }
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {


} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {


} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .woocommerce {
    ul.products[class*=columns-] {
      margin: 0 0 -30px 0;

      li.product {
        width: 48%;
        margin-bottom: 40px;
      }
    }

    .woocommerce-ordering {
      float: left;
    }
  }

  .woocommerce-page {
    .woocommerce-ordering {
      float: left;
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .woocommerce {
    ul.products[class*=columns-] {
      margin: 0 0 -30px 0;

      li.product {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }


}
