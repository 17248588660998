/* ================================
=Desktop
================================ */

.news, .news-home {
  border-top:1px solid $line-color;

  .thumbnail {
    display: block;
    border: none;
    padding: 0;
    margin-bottom: 40px;
    text-align: left;
    position: relative;
    transition: $transition;

    .newsimage {
      width: 100%;
      height: 210px;
      margin-right: 45px;
      position: relative;
      overflow: hidden;
      display: block;

      &:before {
        @include overlay;
        background-color: rgba(0, 0, 0, 0);
      }

      img {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: ease 500ms;
        -webkit-backface-visibility: hidden;
      }
    }

    &:hover, &:active, &:focus {
      text-decoration: none;

      img {
        transform: scale(1.2,1.2);
      }

      .caption {
        p.exerpt {
          span {
            color: $text-link-color-hover;
          }
        }
      }
    }

    .caption {
      background-color: #fff;
      padding: 0;
      position: relative;
      z-index: 1;

      p.date {
        font-size: 13px;
        color: $middle-grey;
        font-weight: $font-weight-regular;
        margin: 10px 0 0 0;
      }

      p.exerpt {
        min-height: 85px;

        span {
          color: $text-link-color;
          font-weight: $font-weight-bold;
          display: block;
          text-transform: uppercase;
        }
      }

      .titledate {
        padding-bottom: 10px;
        text-align: center;

        h4 {
          font-weight: $font-weight-light;
          margin: 15px 0 5px 0;

          a {
            color: $paragraph-color;
          }
        }
      }
    }
  }

  .news-single {
    text-align: left;

    h3 {
      text-align: left;
      margin-bottom: 0;
      text-transform: uppercase;

      &:after {
        left: 0;
        margin: 10px 0px 20px 0;
      }
    }

    img#single-image {
      margin: 20px 0;
      width: 100%;
      object-fit: cover;
    }

    p.date {
      opacity: 0.5;
      margin-bottom: 0px;
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

  .news, .news-home {
    .thumbnail {
      .caption {
        p.exerpt {
          min-height: 115px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  .news, .news-home {
    .home {
      .row {
        .thumbwrap {
          &:last-child {
            display: inline-block;
          }
        }
      }
    }

    .thumbnail {
      .caption {
        .titledate {
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .news, .news-home {
    .home {
      .row {
        .newswrap {
          &:last-child {
            display: block;
          }
        }
      }
    }
  }

  .news-home {
    display: none;
  }

} /* End of view */
