/*
Theme Name: RM Shop
Theme URI: http://www.refresh-media.nl
Author: Refresh Media
Author URI: http://www.refresh-media.nl
Description: Webshop theme developed by Refresh Media.
*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import "../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "../../node_modules/hover.css/css/hover";

@import "_variables";
@import "_default";
@import "_image-header";
@import "_slideshow";

@import "_usps";
@import "_discounts";
@import "_custom-post-type";
@import "_image-fullwidth-c2a";
@import "_accordion";
@import "_forms";
@import "_sidebar";
@import "_footer-col-four";
@import "_menu-shop-fullwidth";
@import "_product-highlight";
@import "_instagram";
@import "_woo-general";
@import "_mini-cart";
@import "_category-slideshow";
@import "_products";
@import "_product-detail";
@import "_checkout";
@import "_account";
@import "_woo-form";
@import "_woo-gallery";

@import "../../node_modules/font-awesome/scss/font-awesome";

/* ================================
=Header
================================ */

/* ================================
=Body
================================ */

.page-template-template-homepage:not(.has-post-thumbnail) .site-main {
    padding-top: 0px;
}

.site-content {
  background-color: $bg-color-light;
}

.section-grey {
  background-color: $bg-color-light;
}

/* ----- =Breadcrums ------ */

  div.breadcrumb {
    margin: 0;
    padding: 25px 0;
    background-color: $bg-color-light;

    a {
      color: $primary-color;
      font-size: 13px;
      padding: 0 5px;

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    span {
      color: $paragraph-color;
      font-size: 13px;
      padding: 0;
      margin: 0;
    }
  }

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  /* ----- =Breadcrums ------ */

  section.breadcrumbs {
    p.breadcrumb {
      font-size: 15px;
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

} /* End of view */

/* ================================
=Footer
================================ */
