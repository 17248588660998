/* === Initialize colors === */

$nocolor: transparent;
$white: #fff;
$black: #000;
$lightest-grey: #f6f6f6;
$lighter-grey: #f1f1f1;
$light-grey: #e6e6e6;
$middle-grey: #b3b3b3;
$darkest-grey: #222;
$darker-grey: #4D4D4D;
$dark-grey: #888;

$red: #E6007E;
$green: #00a725;

$primary-color: #E6007E; // Pink
$primary-color-hover: #ff1d8c; // Pink

$secondary-color: #000; // Black
$secondary-color-hover: #4D4D4D; // Black

/* === Arrange colors === */

// Header menu top colors
$header-menu-top-bg-color: $white;
$header-menu-top-link-bg-color: $white;
$header-menu-top-link-text-color: $darker-grey;
$header-menu-top-link-bg-color-hover: $white;
$header-menu-top-link-text-color-hover: $primary-color;

// Header menu bottom colors
$header-menu-bottom-bg-color: $white;
$header-menu-bottom-link-bg-color: $white;
$header-menu-bottom-link-text-color: $darker-grey;
$header-menu-bottom-link-bg-color-hover: $white;
$header-menu-bottom-link-text-color-hover: $primary-color;

// Mobile menu search
$mobile-menu-search-bg-color: $lightest-grey;
$mobile-menu-search-input-bg-color: $white;

// Footer top colors
$footer-bg-color-top: $black;
$footer-text-color-top: $white;
$footer-link-color-top: $white;
$footer-link-color-top-hover: $primary-color;

// Footer bottom colors
$footer-bg-color-bottom: $white;
$footer-bg-text-color-bottom: $darker-grey;

// Buttons colors
$button-text-color: $black;
$button-text-color-hover: $white;
$button-bg-color: $nocolor;
$button-bg-color-hover: $primary-color;
$button-border-color: $black;
$button-border-color-hover: $primary-color;

// text colors
$paragraph-color: $darker-grey;
$text-link-color: $primary-color;
$text-link-color-hover: $black;
$h1-color: $black;
$h2-color: $black;
$h3-color: $black;
$h4-color: $black;

$paragraph-color-bg: $white;
$text-link-color-bg: $white;
$h1-color-bg: $white;
$h2-color-bg: $white;
$h3-color-bg: $white;
$h4-color-bg: $white;

// Sidebar colors
$sidebar-bg-color: $white;
$sidebar-link-color: $darker-grey;

// Form colors
$label-color: $black;
$form-text-color: $dark-grey;
$form-bg-color: $lightest-grey;
$placeholder-color: $dark-grey;

// Line colors
$line-color: $lighter-grey;
$line-color-hover: $light-grey;

// Background colors
$bg-color-light: $lightest-grey;
$bg-color-dark: $darkest-grey;

// Icons colors
$icon-color: $primary-color;
$icon-color-bg: $black;
$icon-color-hover: $white;

/* === Initialize fonts === */

/* --- Fonts ---*/
$font-family: "Montserrat", sans-serif;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-size-text-regular: 14px;

// Animations
$transition: ease all 0.2s;

// Misc
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$border-radius: 0px;

/* === Woocommerce variables === */

 // Woo colors
$price-color: $red;
