/* ================================
=Desktop
================================ */

.panel-group {
  .panel {
    border-radius: 0px;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

    &.panel-default {
      .panel-heading {
        padding: 0px;
        color: #fff;
        border-radius: 0px;

        h4 {
          a {
            background: $primary-color;
            text-decoration: none;
            color: #fff;
            line-height: 40px;
            padding: 10px 25px;
            display: block;
            font-size: 20px;

            &:after {
              content: "-";
              position: absolute;
              right: 35px;
              font-size: 30px;
            }

            &.collapsed {
              background: #fff;
              color: $paragraph-color;

              &:after {
                content: "+";
                position: absolute;
                right: 35px;
              }
            }

            &:hover, &:focus {
              background: $primary-color;
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }
      .panel-body {
        padding: 20px 25px;
        p {
          margin: 0;
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .panel-group {
    margin-bottom: 40px;
    .panel {
      &.panel-default {
        .panel-heading {
          h4 {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

} /* End of view */
