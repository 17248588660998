/* ================================
=Desktop
================================ */

/* ----- =Intro ------ */

section.intro {
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height:500px;
  padding: 0;
  -webkit-backface-visibility: hidden;

  &.page {
    height: 350px;
    text-align: center;

    .inner {
      height: 350px;

      .inner-content {
        left: 0;

        h1 {
          font-size: 55px;
          text-transform: capitalize;

          span {
            text-transform: none;
          }
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;
    position: relative;
    z-index: 1;
    text-align: center;

    .inner-content {
      padding: 15px;
      text-align: center;

      h1 {
        color: #fff;
        margin: 0;
        padding: 0;
        font-size: 58px;
        text-transform: uppercase;
        line-height: 100%;
        font-weight: $font-weight-bold;
        text-shadow: 2px 2px 8px #313131;
        display: inline-block;
        position: relative;
      }

      p {
        font-size: 26px;
        font-weight: $font-weight-regular;
        text-transform: uppercase;
        text-shadow: 2px 2px 8px #313131;
        margin-top: 10px;
        color: $paragraph-color-bg;
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}


/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  section.intro {
    height:400px;

    &.page {
      height: 200px;

      .inner {
        height: 200px;

        .inner-content {
          float: left;
        }
      }
    }

    .inner {
      height: 400px;

      .inner-content {
        left: 0;

        p {
          font-size: 20px;
        }

        a.hvr-sweep-to-right {
          margin-top: 10px;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  /* ----- =Intro ------ */

  section.intro {
    height: 350px;

    &.page {
      .inner {
        .inner-content {
          h1 {
            font-size: 32px;
          }
        }
      }
    }

    .inner {
      height: 350px;

      .inner-content {
        h1 {
          font-size: 32px;
        }
      }
    }
  }

  .page {
    ul {
      padding-left: 30px;

      li {
        font-size: 15px;
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  /* ----- =Intro ------ */

  section.intro {
    &.page {
      .inner {
        .inner-content {
          h1 {
            font-size: 28px;
          }
        }
      }
    }

    .inner {
      .inner-content {
        h1 {
          font-size: 28px;
        }
      }
    }
  }

} /* End of view */
