/* ------ =Forms ------ */

.wpcf7-form, .newsletter {
  margin-top: -5px;
  .btn-primary {
    padding: 20px 30px;

    &:hover {
      background-color: $form-bg-color;
    }
  }

  input[type="text"], input[type="email"], textarea {
    margin-top: 5px;
    border-radius: 0px;
    padding: 10px 20px;
    box-shadow: none;
    border: none;
    background-color: $form-bg-color;
    max-width: 100%;

    &:focus {
      border-color: $black;
      box-shadow: none;
    }
  }

  textarea {
    height: auto;
    height: 200px;
    width: 100%;
  }

  input[type="submit"] {
    color: #fff;
    background: $primary-color repeat scroll 0 0;
    border-color: transparent;
    border-radius: 0px;
    text-transform: uppercase;
    padding: 15px 30px;
    margin: 10px 0 10px 0;
    font-size: 14px;
    border: transparent;
    border-radius: $border-radius;
    overflow: hidden;
    font-weight: $font-weight-bold;
    transition: $transition;

    &:hover {
      background: $primary-color-hover repeat scroll 0 0;
    }
  }
}

.form-control {
  height: 60px;
  margin-top: 5px;
  border-radius: 0px;
  padding: 20px 25px;
  box-shadow: none;
  border: #fff;

  &:focus {
    border-color: $line-color-hover;
    box-shadow: none;
  }
}

textarea.form-control {
    height: auto;
    min-height: 200px;
    padding: 20px 20px;
}

// Newsletter

.newsletter {
  background-color: $primary-color;
  padding: 40px 0;

  h2 {
    color: $white;
  }

  .mc4wp-form {
    position: relative;

    .mc4wp-response {
      p {
        color: #fff;
      }
    }

    .form-field {
      &.form-field {
        position: relative;
        .g-recaptcha {
          position: absolute;
          display: none;
        }
      }

      span {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-transform: uppercase;
        color: $white;
      }

      .input {
        margin-top: 5px;
        border-radius: 0px;
        padding: 10px 20px;
        background-color: #fbb8dc;
        width: 100%;
        color: $black;
        box-shadow: none;
        border: 1px solid $white;
        box-shadow: $box-shadow;
        height: inherit;
        font-size: 14px;
        color: $placeholder-color;

        &:focus {
          border-color: $black;
          box-shadow: none;
        }

        &::placeholder {
          color: $white;
        }

        &.required {
          border: 1px solid red;
          background-color: #efd8d8;
          color: $black;

          &::placeholder {
            color: $red;
          }
        }
      }
      #submit {
        color: $white;
        background-color: $black;
        border: 1px solid $white;
        padding: 11px 20px;
        margin: 5px 0 0 0;
        height: inherit;
        width: 100%;
        max-width: 100%;
      }
    }

    #g-recaptcha-error {
      float: left;
      text-align: right;
      display: block;
      width: 100%;
      color: $red;
      margin-top: 10px;
    }
  }

  .g-recaptcha {
    position: relative;
    div {
      position: absolute;
      left: -2px;
      width: 300px;
      height: 76px;
      border-radius: 0px;
      overflow: hidden;
    }
  }
}

/* --- Woocommerce forms --- */

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  background-color: $form-bg-color;
  border: 1px solid $line-color;
  padding: 10px 15px;
}

.woocommerce form .form-row input.input-text:focus, .woocommerce form .form-row textarea:focus {
  background-color: $form-bg-color;
  border: 1px solid $line-color-hover;
  padding: 10px 15px;
}

.woocommerce form .form-row select  {
  border: 1px solid $line-color;
  background-color: $form-bg-color;
  color: $form-text-color;
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 13px;
}

.select2-container--default .select2-selection--single {
  background-color: $form-bg-color;
  border: 1px solid $line-color;
  border-radius: $border-radius;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $form-text-color;
}

/* ------ =Validation ------ */

div.screen-reader-response {
  display: none;
}

.has-error .form-control {
    border-color: $primary-color;
}

.alert-warning, .alert-danger {
    border: none;
    border-radius: 0px;
    font-size: 14px;
    color: $primary-color;
    background-color: $form-bg-color;
    border: 1px solid $red;
    border-radius: 0px;
    margin-top: 10px;
    color: #F46611;
}

/* ------ =Validation ------ */

div.screen-reader-response {
  display: none;
}

.has-error {
  border-color: $red;
}

.alert-warning, .alert-danger {
  border: none;
  border-radius: 0px;
  font-size: 14px;
  color: $red;
  background-color: $light-grey;
  border: 1px solid $red;
  border-radius: 0px;
  margin-top: 10px;
  color: #F46611;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border: 2px solid $red;
  margin: 0px;
  padding: 10px 20px;
}

div.wpcf7-mail-sent-ok {
  border: 2px solid $green;
  margin: 0px;
  padding: 10px 20px;
}

@media screen and (max-width: 1200px) {

  // Newsletter
  .newsletter {
    #form {
        #submit {
          font-size: 13px;
          max-width: 100%;
        }
      }

      .mc4wp-form.mc4wp-form-188 {
        .form-field {
          span {
            font-size: 14px;
            font-weight: 600;
            line-height: 60px;
            text-transform: uppercase;
            color: $white;
          }
        }
      }
    }

    .g-recaptcha {
      div {
        left: inherit;
        right: 0;
        margin-right: 0px;
      }
    }

}


@media screen and (max-width: 991px) {

  // Newsletter
  .newsletter {
    #form {
      .form-field {
        #submit {
          max-width: 100%;
        }
      }

      #g-recaptcha-error {
        padding-right: 15px;
      }
    }

    .g-recaptcha {
      div {
        left: inherit;
        right: 0;
        margin-right: 8px;
      }
    }

    .mc4wp-form.mc4wp-form-188 {
      .form-field {
        span {
          line-height: 55px;
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {

  // Newsletter
  .newsletter {
    #form {

      #g-recaptcha-error {
        text-align: left;
        padding-left: 15px;
      }

      .g-recaptcha {
        margin-top: 15px;


        div {
          height: 90px;
          position: relative;
          margin-top: 15px;
          top: inherit;
          left: inherit;
          right: 0;
          margin-right: 8px;
        }
      }
    }
  }

}
