/* ================================
=Desktop
================================ */

.woocommerce-info {
  font-size: 14px;
  margin-top: 20px;
  border-top-color: $primary-color;

  &:before, &:after {
    color: $icon-color;
  }
}

.woocommerce-message {
  font-size: 14px;
  margin-top: 20px;
  border-top-color: $primary-color;

  &:before, &:after {
    color: $icon-color;
  }

  .button.wc-forward {
    margin: 0;
  }
}

.woocommerce {
  .product {
    .stock {
      font-weight: 600;
    }
  }
}

.woocommerce form .form-row label {
    line-height: 24px;
}

/* Search */

ul.searchresults {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    border-bottom: 1px solid rgba(0,0,0,0.075);
    margin: 20px 0;
    padding: 20px 0;

    &:first-child {
      border-top: 1px solid rgba(0,0,0,0.075);
    }

    &:before {
      display: none;
    }

    div {
      display: inline-block;

      a {
        display: inline-block;

        img {
          max-width: 100%;
          height: auto;
          display: inline-block;
        }
      }

      &:first-child {
        width: 20%;
      }

      &:last-child {
        width: 70%;
      }
    }
  }
}


/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {


} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {


} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .mini-cart {
    display: none;
  }

  /* Search */

  ul.searchresults {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid rgba(0,0,0,0.075);
      margin: 20px 0;
      padding: 20px 0;

      div {
        display: inline-block;

        a {
          display: inline-block;

          img {
            max-width: 150px;
            height: auto;
            display: inline-block;
          }
        }

        &:first-child {
          width: 100%;
          text-align: left;
          margin-bottom: 10px;
        }

        &:last-child {
          width: 100%;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {


}
