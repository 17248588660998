
/* FOOTER FORM */

footer {
  h2 {
    font-size: 19px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 400;
    margin: 10px 0;
  }

  .woocommerce-MyAccount-navigation {
    &:before {
      content:"Mijn account";
      font-size: 19px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 400;
      margin: 7px 0;
      display: inline-block;
    }
  }

  .woocommerce-MyAccount-content {
    display: none;
  }

  .u-column2 {
    display: none;
  }

  .woocommerce form .form-row input[type="text"], .woocommerce form .form-row input[type="password"] {
    border: 1px solid #dddcdc;
    background-color: #565656;
    height: 32px;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
    color: $primary-color;
    font-size: 16px;
    padding: 0 10px;
    border-radius: 0px;
  }

  input.woocommerce-Button.button {
    padding: 3px 20px;
    border: 1px solid #fff;
  }

  .woocommerce form .form-row label {
    color: #fff;
    font-weight: 400;
  }

  .woocommerce form .form-row input:-webkit-autofill {
    -webkit-text-fill-color: rgba(255,255,255,0.5) !important;
     -webkit-box-shadow: 0 0 0 30px #565656 inset;
     border: 1px solid #dddcdc;
  }

  p.lost_password a {
    color: rgba(255,255,255,0.5);
  }

  .woocommerce-message {
    border: 1px solid rgba(255,255,255,0.2);
    padding: 10px;
    color: rgba(255,255,255,0.5);
    background-color: #222222;
    font-size: 14px;
    margin-bottom: 5px;

    &:before {
      display: none;
    }

    a {
      color: #fff;
    }
  }
}

/* GENERAL FORM */

.woocommerce form .form-row label input {
  margin-left: 10px;
}

#form, #footer-form {
    input {
      border: 1px solid #dddcdc;
      background-color: #565656;
      height: 32px;
      width: 100%;
			margin-bottom: 10px;
			font-weight: 400;
      color: $primary-color;
      font-size: 16px;
      padding: 0 10px;
      border-radius: 0px;

			&::placeholder {
				color: #fff;
			}
    }

    textarea {
        border: 1px solid #dddcdc;
        background: #d1f3ff;
        border-radius: 0px;
        width: 100%;
        line-height: 34px;
				color: $primary-color;
				font-weight: 400;
	    	background-color: #565656;
        font-size: 16px;
        padding: 0 10px;
        min-height: 100px;
        border-radius: 0px;

			&::placeholder {
				color: #fff;
			}
    }

	input[type=submit] {
		display: inline-block;
		width: auto;
		float: left;
		font-size: 14px;
		font-weight: 600;
		margin-top: 5px;
		border: 1px solid #fff;
	    background: $primary-color;
	    padding: 6px 25px 26px 25px;

		&:hover {
		    background-color: #fff;
			color: $primary-color;
		}
	}
}

/* Contact Form */

section.content {

  .wpcf7 {
    p {
      margin: 0;

      br {
        display: none;
      }
    }
  }

  label{
    width: 75%;
    margin-top: 5px;
    textarea {
      border: 1px solid #c3c3c3;
      width: 100%;
    }
  }

  input[type="text"], input[type="email"] {
    border: 1px solid #c3c3c3;
    width: 50%;
    padding: 5px 10px;
    margin-top: 5px;
    font-weight: 400;
    display: block;
  }

  input[type="submit"] {
    background-color: $primary-color;
    padding: 10px 30px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  input.wpcf7-not-valid {
    border: 1px solid #d73b3c;
    background-color: #ffe5e6;
  }

  .wpcf7-not-valid-tip {
    display: block;
    color: #d73b3c;
  }
}

@media screen and (max-width: 768px) {
  /* Contact Form */

  section.content {

    label{
      width: 100%;
      text-align: left;
    }

    input[type="text"], input[type="email"] {
      border: 1px solid #c3c3c3;
      width: 100%;
      padding: 5px 10px;
      margin-top: 5px;
      font-weight: 400;
      display: block;
      text-align: left;
    }

    input[type="submit"] {
      float: left;
      margin: 20px 0 30px 0;
    }

    input.wpcf7-not-valid {
      border: 1px solid #d73b3c;
      background-color: #ffe5e6;
    }

    .wpcf7-not-valid-tip {
      display: block;
      color: #d73b3c;
    }
  }
}
