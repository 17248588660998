/* ================================
=Desktop
================================ */

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  font-size: 16px;
  font-family: $font-family;
}

section {
  padding: 60px 0;
}

section.page {
  h1 {
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 20px;
  }
}

.woocommerce .wishlist_table td.product-add-to-cart a {
  line-height: 16px;
  padding: 5px 10px;
}

.wishlist-title {
  h2 {
    &:after {
      display: none;
    }
  }
}

.hvr-sweep-to-right {
  &:before {
    background: $primary-color-hover;
  }
}

a {
  color: $primary-color;
  font-weight: $font-weight-bold;

  &:hover, &:focus {
    color: $primary-color;
  }
}

h1 {
  font-size: 28px;
  font-weight: $font-weight-bold;
  margin: 0;
  color: $h1-color;
  text-transform: uppercase;
}

h2 {
  font-size: 24px;
  color: $h2-color;
  font-weight: $font-weight-regular;
  text-transform: uppercase;
  margin: 0;
  text-align: center;

  &:after {
    content: "";
    background: $primary-color;
    width: 54px;
    height: 4px;
    position: relative;
    left: 50%;
    display: block;
    margin: 10px 0 30px -20px;
  }
}

h3 {
  font-size: 18px;
  font-weight: $font-weight-bold;
  color: $h3-color;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}

h4 {
  font-size: 16px;
  color: $h4-color;
  font-weight: $font-weight-bold;
  margin: 20px 0 10px 0;
}

p, address {
  color: $paragraph-color;
  line-height: 28px;
  font-weight: $font-weight-light;
  font-size: 14px;

  img {
    width: 100%;
    object-position: left;
    object-fit: contain;
  }
}

img {
  vertical-align: middle;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

ul.children {
  li {
    color: $primary-color;
  }
}

// Buttons
.btn-primary, .btn-ghost, .btn-ghost-white-bg, .button, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, a.button.wc-forward, .button.checkout.wc-forward, .woocommerce-account .addresses .title .edit, .woocommerce button.button.alt, .woocommerce a.button.alt, .woocommerce div.product form.cart .button {
  color: $button-text-color;
  background-color: #fff;
  border-color: transparent;
  border-radius: 0px;
  line-height: 28px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin: 0px;
  font-size: 12px;
  border: 3px solid $button-border-color;
  overflow: hidden;
  font-weight: $font-weight-bold;
  transition: $transition;

  &:hover, &:focus, a:active {
    background: $button-bg-color-hover repeat scroll 0 0;
    color: #fff;
    text-decoration: none;
    border: 3px solid $button-border-color-hover;
  }
}

.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, a.button.wc-forward, .button.checkout.wc-forward, .woocommerce-account .addresses .title .edit, .woocommerce button.button.alt {
  padding: 5px 15px;
}

.btn-ghost {
  background: transparent;
  border: 2px solid rgba(255,255,255,0.4);
  color: $white;
  margin-top: 20px;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: $font-weight-bold;
  transition: $transition;

  &:focus, &:link, a:active {
    background-color: transparent;
    color: #fff;
    font-weight: $font-weight-bold;
    border: 2px solid rgba(255,255,255,0.4);
  }

  &:hover {
    border: 2px solid #fff;
    color: #fff;
  }
}

.woocommerce button.button.alt, .woocommerce a.button.alt, .woocommerce div.product form.cart .button {
  background-color: $primary-color;
  color: $white;
  border: 3px solid $primary-color;
}

.woocommerce button.button.alt:hover, .woocommerce a.button.alt:hover, .woocommerce div.product form.cart:hover .button:hover {
  background-color: $primary-color-hover;
  color: $white;
  border: 3px solid $primary-color-hover;
}

// Overlay
@mixin overlay {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

// Overlay
@mixin overlay-gradient {
  content: "";
  position: absolute;
  display: block;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to top right, rgba(222, 101, 124, 0.9), rgba(242, 153, 67, 0.9));
}

// Scroll animation

.scroll-left {
  position: relative;
  left: -500px;
  opacity: 0;
  transition: ease 1s;

  &.visible {
    opacity: 1;
    position: relative;
    left: 0;
  }
}

.scroll-fade {
  opacity: 0;
  transition: ease 1s;

  &.visible {
    opacity: 1;
  }
}

.parallax-mirror {
  z-index: 0!important;
}

/* ================================
=Small desktop (min. 1200px)
================================ */

@media screen and (min-width: 1200px) {

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

} /* End of view */

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  section {
    padding: 60px 0;
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  body {
    margin-bottom: 0px;
  }

  section {
    padding: 30px 0;
  }

  p {
    color: $paragraph-color;
    line-height: 28px;
    font-weight: $font-weight-light;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    -webkit-backface-visibility: hidden;
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 15px;
  }

}
