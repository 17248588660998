/* ================================
=Desktop
================================ */

.usps-fullwidth {
  padding: 15px 0;
  background-color: $bg-color-dark;
  line-height: 40px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

  &.home {
    background-color: $bg-color-dark;
    box-shadow: none;
  }

  .container {
    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline-block;
        font-size: $font-size-text-regular;

        span.usp-text {
          display: inline-block;
          float: left;
          width: 100%;
          margin-left: 8%;
          text-align: left;
          color: $paragraph-color-bg;
          line-height: 22px;

          svg {
            font-family: "Font Awesome 5 Pro";
            font-size: 20px;
            display: inline-block;
            float: left;
            margin-right: 10px;
            color: $primary-color;
          }
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .usps-fullwidth {
    padding: 15px 0;
    background-color: $bg-color-dark;
    line-height: 40px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

    &.home {
      background-color: $bg-color-dark;
      box-shadow: none;
    }

    .container {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          font-size: $font-size-text-regular;

          span.usp-text {
            display: inline-block;
            float: left;
            width: 100%;
            text-align: left;
            color: $paragraph-color-bg;
            line-height: 22px;
            margin: 0;
          }
        }
      }
    }
  }

} /* End of view */
