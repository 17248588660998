/* ================================
=Desktop
================================ */

.push {
  min-height: 140px;
}

.current-post-parent {
  li.current-menu-item {
    display: none;
  }
}

.secondary-menu {
  display: none;
}

/*--- Mobile menu button ---*/

.menu-buttons-mobile {
  position: fixed;
  z-index: 2;
  bottom: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  background-color: $bg-color-dark;
  padding: 10px 0;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
  display: none;

  .myaccount-icon, .shop-icon, .wishlist-icon, .search-icon {
    position: relative;
    padding: 0 10px;
    font-size: 20px;
    display: inline-block;
    background-color: transparent;
    border: none;
    color: $white;
    outline: none;
    transition: $transition;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;

    &.topnav-open {
      &:after {
        content: "\f00d";
      }
    }
  }
}

/*--- Search mobile ---*/

.searchbar {
  background-color: $mobile-menu-search-bg-color;
  position: fixed;
  width: 100%;
  bottom: 50px;
  padding: 10px 0;
  text-align: center;
  box-shadow: 0 2px 1px 3px rgba(0, 0, 0, 0.10);
  transition: $transition;
  bottom: -100px;

  h4 {
    display: none;
  }

  form {
    margin: 0 auto;
    position: relative;
    width: 100%;
    transition: $transition;

    input[type="text"] {
      height: 40px;
      padding: 0 15px;
      width: 100%;
      font-size: 13px;
      border: 1px solid $line-color;
      color: $form-text-color;
      background-color: $mobile-menu-search-input-bg-color;
      transition: $transition;

      &:focus {
        border: 1px solid $line-color-hover;
        box-shadow: none;
        outline: none;
      }
    }

    input[type="submit"] {
      display: none;
    }

    ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
      color: $placeholder-color;
    }

    &:after {
      content: "\f002";
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      top: 9px;
      right: 15px;
      color: $icon-color-bg;
      transition: $transition;
    }
  }

  svg {
    position: absolute;
    top: 7px;
    right: 15px;
    color: $icon-color;
  }

}

.search-open {
  .searchbar {
    bottom: 50px;
  }
}


/*--- Head menu ---*/

.navbar {
  &.navbar-default {
    background-color: $header-menu-top-bg-color;
    border: none;
    -webkit-transition: $transition;
    transition: $transition;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);

    &.navbar-fixed-top {
      z-index: 2;

      .topnav {
        line-height: 80px;
        height: 80px;
        transition: $transition;

        ul {
          float: right;
          height: 100%;
          line-height: 80px;
          list-style-type: none;
          margin: 0;

          li {
            margin-left: 20px;
            float: left;

            a {
              color: $header-menu-top-link-text-color;
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 400;
            }
          }
        }
      }

      .navbar-header {
        float: none;
        height: 80px;
        transition: $transition;

        .container {
          .head-left {
            display: none;
          }

          .head-search {
            text-align: center;
            line-height: 80px;
            height: 80px;
            transition: $transition;

            form {
              margin: 0 auto;
              height: 80px;
              line-height: 80px;
              position: relative;
              width: 100%;
              transition: $transition;

              input[type="text"] {
                height: 40px;
                padding: 0 15px;
                width: 100%;
                font-size: 13px;
                border: 1px solid $line-color;
                color: $form-text-color;
                background-color: $form-bg-color;
                transition: $transition;

                &:focus {
                  border: 1px solid $line-color-hover;
                  box-shadow: none;
                  outline: none;
                }
              }

              input[type="submit"] {
                display: none;
              }

              ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
                color: $placeholder-color;
              }

              &:after {
                content: "\f002";
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                top: 0;
                right: 15px;
                color: $icon-color-bg;
                transition: $transition;
              }
            }
          }

          /* ----- =Navbar-toggle ------ */
          .navbar-toggle {
            position: absolute;
            z-index: 3;
            left: 15px;
            top: 8px;
            margin: 0;
            padding: 14px 20px 20px 10px;
            background-color: transparent;
            background-image: none;
            border: none;

            &:hover, &:focus {
              background: none;
            }

            // styles to `:focus`.
            &:focus {
              outline: 0;
            }

            // Bars
            .icon-bar {
              position: absolute;
              display: block;
              top: 6px;
              right: 0px;
              width: 28px;
              height: 3px;
              border-radius: 1px;
              -webkit-transition: all ease-in-out 0.3s;
              -o-transition: all ease-in-out 0.3s;
              transition: all ease-in-out 0.3s;

            }
            .icon-bar:nth-child(2){
              margin-top: 4px;
              background-color: $primary-color;
            }
            .icon-bar:nth-child(3) {
              margin-top: 10px;
              background-color: $primary-color;
            }
            .icon-bar:nth-child(4) {
              margin-top: 16px;
              background-color: $primary-color;
            }

            &.nav-open {
              .icon-bar {
                -webkit-transition: all ease-in-out 0.3s;
                -o-transition: all ease-in-out 0.3s;
                transition: all ease-in-out 0.3s;
              }
              .icon-bar:nth-child(2){
                background-color: transparent;
              }
              .icon-bar:nth-child(3) {
                top: 8px;
                transform: rotate(45deg);
              }
              .icon-bar:nth-child(4) {
                top: 2px;
                transform: rotate(-45deg);
              }
            }
          }

          .navbar-brand {
            -webkit-transition: $transition;
            transition: $transition;
            position: relative;
            z-index: 999;
            padding: 0;
            display: inline-block;
            height: 80px;
            line-height: 80px;

            img {
              display: inline-block;
              transition: $transition;
            }
          }
        }
      }

      .shop-menu {
        float: right;
        list-style-type: none;
        height: 80px;
        line-height: 80px;
        margin: 0;
        transition: $transition;

        li {
          line-height: 40px;
          padding: 0px 15px;
          margin-top: 24px;
          transition: $transition;

          a {
            height: 40px;
            padding: 0px 15px;
            display: block;
            border: 1px solid $line-color;

            span.cartcontents {
              margin-right: 7px;
              font-weight: 400;
              font-size: 14px;
              color: $paragraph-color;
            }

            span.amount {
              color: $paragraph-color;
              font-weight: 400;
              font-size: 14px;
            }

            &:hover {
              color: $paragraph-color;
              text-decoration: none;
              border: 1px solid $line-color;

              span.cartcontents, span.amount {
                color: $primary-color;
              }
            }
          }
        }
      }

      .collapse {
        &.navbar-collapse {
          background: $header-menu-bottom-link-bg-color;
          border-top: 1px solid $line-color;

          .container {
            position: relative;

            .search-icon {
              position: absolute;
              right: 15px;
              top: 16px;
              font-size: 18px;
              background-color: transparent;
              border: none;
              color: #fff;
              outline: none;
              transition: $transition;

              &:after {
                content: "\f002";
                display: inline-block;
                font-family: fontawesome;
                transition: $transition;
              }
            }

            .search {
              position: absolute;
              right: 0;
              top: -200px;
              opacity: 0;
              margin-top: 24px;
              transition: $transition;

              input[type="text"] {
                padding: 12px 20px;
                border: none;
                box-shadow: 0 1px 1px -1px #000;
              }
            }

            .navbar-nav {
              line-height: 30px;
              float: none;
              -webkit-transition: $transition;
              transition: $transition;

              li {
                a {
                  background-color: $header-menu-bottom-link-bg-color;
                  line-height: 30px;
                  font-weight: 600;
                  font-size: 13px;
                  text-transform: uppercase;
                  position: relative;
                  margin: 0;
                  padding-right: 10px;
                  padding-left: 10px;
                  color: $header-menu-bottom-link-text-color;
                  transition: $transition;

                  &:before, &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    -webkit-transform: scale3d(0, 1, 1);
                    transform: scale3d(0, 1, 1);
                    -webkit-transform-origin: center left;
                    transform-origin: center left;
                    -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                    transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                  }

                  &:hover {
                    color: $header-menu-bottom-link-text-color-hover;
                  }

                  &:before {
                    -webkit-transition-delay: 0.2s;
                    transition-delay: 0.2s;
                    background: rgba(0, 0, 0, 0) $primary-color repeat scroll 0 0;
                  }

                  &:after {
                    background: $primary-color;
                  }

                  .active, :hover, :focus, :visited {
                    a {
                      background-color: $header-menu-bottom-link-text-color-hover;
                    }

                    &:hover, &:focus {
                      &:before {
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                        -webkit-transition-delay: 0s;
                        transition-delay: 0s;
                      }
                    }

                    .open {
                      a {
                        color: $header-menu-bottom-link-text-color-hover;
                      }
                    }
                  }
                }


                &.active {
                  a:hover, a:focus {
                    background-color: $header-menu-bottom-link-bg-color-hover;
                    color: $header-menu-bottom-link-text-color-hover;
                  }

                  a {
                    color: $header-menu-bottom-link-text-color-hover;
                    background-color: $header-menu-bottom-link-bg-color-hover;
                    text-transform: uppercase;
                  }
                }

                &.open {
                  .dropdown-toggle {
                    background-color: $header-menu-bottom-link-bg-color;
                    color: $header-menu-bottom-link-text-color;
                  }
                }

                ul {
                  box-shadow: $box-shadow;

                  li {
                    a {
                      padding: 10px 20px;
                    }

                    &:hover, &.active {
                      a {
                        background-color: $header-menu-bottom-link-bg-color-hover;
                      }
                    }

                    &.active {
                      &:hover {
                        a {
                          background-color: $header-menu-bottom-link-bg-color-hover;
                        }
                      }
                    }
                  }
                }
              }
            }

          }

          &.search-open {

            .container {
              .search-icon {
                &:after {
                  content: "\f00d";
                }
              }

              .search {
                top: 36px;
                opacity: 1;
              }
            }
          }
        }
      }

      &.scrolled {

        .topnav {
          margin-top: -30px;
        }

        .navbar-header {
          height: 60px;

          .container {
            .navbar-brand {
              line-height: 60px;
              height: 60px;

              img {
                max-width: 125px;
              }
            }

            .head-search {
              text-align: center;
              line-height: 60px;
              height: 60px;

              form {
                margin: 0 auto;
                height: 60px;
                line-height: 60px;
                position: relative;
                width: 300px;

                input {
                  height: 36px;
                  padding: 0 15px;
                  width: 300px;
                  border: 1px solid $line-color;
                  color: $form-text-color;
                }

                ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
                  color: $placeholder-color;
                }

                svg {
                  position: absolute;
                  top: 22px;
                  right: 15px;
                  color: $icon-color;
                }
              }
            }

            .shop-menu {
              height: 60px;
              line-height: 60px;

              li {
                line-height: 32px;
                padding: 0px 15px;
                margin-top: 13px;
                a {
                  height: 36px;
                  padding: 0px 15px;
                }
              }
            }
          }
        }

        .collapse {
          &.navbar-collapse {
            .container {
              .navbar-nav {
                line-height: 40px;

                li {
                  a {
                    line-height: 20px;
                    font-size: 13px;
                  }
                }
              }

              .search-icon {
                top: 11px;
              }
            }



            &.search-open {

              .container {

                .search-icon {
                  position: absolute;
                  right: 15px;
                  top: 11px;
                  font-size: 18px;
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  outline: none;
                  transition: $transition;

                  &:after {
                    content: "\f002";
                    display: inline-block;
                    font-family: fontawesome;
                    transition: $transition;
                  }
                }

                .search-icon {
                  &:after {
                    content: "\f00d";
                  }
                }

                .search {
                  top: 26px;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ----- =Dropdown ------ */

.navbar-default {
  .navbar-nav {
    .open {
      &:hover {
        border: none;

        li>a {
          text-decoration: none;
        }

        a {
          border: none;
          color: $header-menu-bottom-link-text-color;
        }
      }

      a {
        border: none;
        background-color: $header-menu-bottom-link-bg-color;

        &:hover, &:focus {
          border: none;
          background-color:$header-menu-bottom-link-bg-color;
          color: $header-menu-bottom-link-text-color;

          &:before, &:after {
            display: none;
          }
        }
      }
    }

    ul.dropdown-menu {
      border: none;
      background-color: $header-menu-bottom-link-bg-color;
      border-radius: 0px;
      box-shadow: none;
      padding: 0;
      list-style-position: inside;

      li {
        padding: 0;
        width: 100%;
        display: block;

        a {
          font-size: 13px;
          color: $header-menu-bottom-link-text-color;
          background-color: $header-menu-bottom-link-bg-color;
          padding: 0 20px;
          margin: 0;
          width: 100%;
          display: block;
        }

        &:hover {
          a {
            color: $header-menu-bottom-link-text-color-hover;
            background-color: $header-menu-bottom-link-bg-color;
          }
        }
      }
    }
  }
}

ul.dropdown-menu {
  transition: $transition;
  display: inline-block;
  margin-top: -200px !important;
  position: absolute;
  z-index: -2;
  opacity: 0;
}

.open {
  ul.dropdown-menu {
    display: inline-block;
    margin-top: 0px !important;
    position: absolute;
    opacity: 1;
  }
}

/*--- Wp Menu Cart ---*/

.navbar.navbar-default.navbar-fixed-top {
  .collapse.navbar-collapse {
    .container {
      .navbar-nav {
        li.wpmenucartli.wpmenucart-display-standard.menu-item.menu-item-type-taxonomy.menu-item-object-product_cat {
          float: right;

          a {
            background-color: $primary-color;
            color: $white;

            &:hover {
              background-color: $primary-color-hover;
            }
          }
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 1024px) {

  .mini-cart li a.dropdown-toggle.cart {
      color: #c2a373;
      border: 3px solid #c2a373;
      line-height: 32px;
      margin-top: 11px;
      font-size: 0px;
      font-weight: 600;
      display: inline-block;
      padding: 0 8px;
      position: relative;
      transition: ease all 0.2s;
      box-sizing: border-box;
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 991px) {

  /*--- Search mobile ---*/

  .searchbar {
    h4 {
      display: block;
      margin: 0 0 8px 0;
    }
    form {
      &:after {
        display: none;
      }
    }
  }

  /*--- Wp Menu Cart ---*/

  li.wpmenucartli.wpmenucart-display-standard.menu-item.menu-item-type-taxonomy.menu-item-object-product_cat {
    display: none;
  }

  .menu-buttons-mobile {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    text-align: center;
    background-color: $bg-color-dark;
    padding: 10px 0;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
    display: block;

    .myaccount-icon, .shop-icon, .wishlist-icon {
      position: relative;
      padding: 0 10px;
      font-size: 20px;
      display: inline-block;
      background-color: transparent;
      border: none;
      color: $white;
      outline: none;
      transition: $transition;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      &.topnav-open {
        &:after {
          content: "\f00d";
        }
      }
    }
  }

  .secondary-menu {
    display: block;

    ul {
      float: left;
      padding: 0;
      margin: 0;
      width: 100%;
      border-top: 1px solid rgba(0,0,0,0.1);

      li {
        list-style-type: none;
        border-bottom: 1px solid $lighter-grey;

        a {
          line-height: 18px;
          padding: 5px 15px;
          margin: 0;
          color: $header-menu-bottom-link-text-color;
          font-size: 12px;
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }

  .mini-cart {
    display: none;
  }

  header {
    .navbar {
      &.navbar-default {
        &.navbar-fixed-top {

          .topnav {
            transition: $transition;
            position: absolute;
            top: -100px;
            width: 100%;
            opacity: 0;
            height: auto;

            .container {
              ul {
                line-height: 30px;
                float: inherit;
                margin: 0;
                padding: 0;

                li {
                  float: none;
                  display: block;
                  width: 100%;
                  position: relative;
                  margin: 0;

                  a {
                    line-height: 35px;
                    padding: 5px 15px;
                    margin: 0;
                    color: $header-menu-bottom-link-text-color;
                    font-size: 13px;
                    display: block;
                    font-weight: 600;
                    text-transform: uppercase;
                  }
                }
              }
            }
          }

          .topnav {
            &.topnav-open {
              display: block;
              top: 102px;
              opacity: 1;
            }
          }

          .navbar-header {
            height: 50px;
            .container {
              float: none;
              // margin: 0 -15px;
              margin: 0;

              .head-left {
                display: block;
              }

              .head-center {
                text-align: right;
                position: absolute;
                z-index: 1;
                width: 100%;
              }

              .head-search {
                display: none;
              }

              .navbar-toggle {
                display: block;
                &.collapsed {

                }
              }

              .navbar-brand {
                margin: 0;
                padding: 0;
                line-height: 50px;
                height: 50px;
                width: 100%;

                img {
                  max-width: 125px;
                  margin-top: -5px;
                }
              }

              .shop-menu {
                position: absolute;
                top: 0px;
                right: 30px;
                height: 50px;
                line-height: 50px;

                li {
                  margin-top: 0px;
                  line-height: 50px;
                  padding: 0;

                  a {
                    border: none;
                    &:before {
                      content:"\f07a";
                      font-family: fontawesome;
                      display: inline-block;
                      margin-right: 10px;
                      font-size: 20px;
                    }

                    span.cartcontents, span.amount {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .navbar-collapse, .topnav {
            max-height: inherit;
            padding: 0;
            background: $header-menu-top-bg-color;

            &.collapse {
              display: none!important;

              &.in {
                display:block !important;
              }
            }

            .container {
              margin: 0;
              padding: 0;
              width: 100%;

              .search-icon, .search {
                display: none;
              }

              .nav {
                &.navbar-nav {
                  line-height: 30px;
                  float: inherit;
                  margin: 0;
                  padding: 0;
                  width: 100%;

                  li {
                    float: none;
                    border-bottom: 1px solid $line-color;

                    ul {
                      li {
                        border-bottom: none;
                      }
                    }

                    &.open {
                      a {
                        &.dropdown-toggle {
                          border-bottom: none;
                        }
                      }
                    }

                    a {
                      line-height: 18px;
                      padding: 5px 15px;
                      margin: 0;
                      color: $header-menu-bottom-link-text-color;
                      font-size: 12px;
                      display: block;
                      text-transform: uppercase;

                      .caret {
                        position: absolute;
                        right: 15px;
                        top: 20px;
                        border-top: 6px dashed;
                        border-right: 5px solid transparent;
                        border-left: 5px solid transparent;
                      }

                      &:before {
                        display: none;
                      }
                    }
                  }

                  .active {
                    a, a:hover {
                      color: $header-menu-bottom-link-text-color-hover;
                      border: none;
                      background-color: $header-menu-bottom-link-bg-color-hover;

                      &:before, &:after {
                        display: none;
                      }
                    }
                  }

                  &:hover {
                    a {
                      border: none;
                      color: $header-menu-bottom-link-text-color;
                    }
                  }

                  ul.dropdown-menu {
                    background-color: $header-menu-bottom-bg-color;
                    padding: 5px 30px;
                    position: relative;
                    z-index: -1;
                    opacity: 0;
                    top: 0;
                    transition: $transition;
                    width: 100%;
                    display: none;

                    li {
                      &:hover {
                        a {
                          color: $header-menu-bottom-link-text-color-hover;
                        }
                      }
                    }
                  }

                .open {
                    a {
                      color: $header-menu-bottom-link-text-color;
                    }

                    &:hover a {
                      color: $header-menu-bottom-link-text-color-hover;
                    }

                    ul.dropdown-menu {
                      z-index: 1;
                      top: 0;
                      opacity: 1;
                      display: inline-block;
                      border-top: 1px solid rgba(0,0,0,0.1);
                      border-bottom: 1px solid rgba(0,0,0,0.15);
                      box-shadow: none;

                      li {
                        a {
                          color: $header-menu-bottom-link-text-color;
                          line-height: 20px;
                          background-color: $header-menu-bottom-link-bg-color;

                          &:hover, &focus {
                            color: $header-menu-bottom-link-text-color-hover;
                          }
                        }
                      }

                      .active {
                        a {
                          background-color: $header-menu-bottom-link-bg-color-hover;
                          color: $header-menu-bottom-link-text-color-hover;
                          line-height: 30px;
                        }
                        &:hover {
                          a {
                            background: $header-menu-bottom-link-bg-color-hover;
                            color: $header-menu-bottom-link-text-color-hover;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Scrolled

    .navbar {
      &.navbar-default {
        &.navbar-fixed-top.scrolled {

          .topnav {
            margin: 0;
          }

          .collapse {
            &.navbar-collapse {
              .container {
                .navbar-nav {
                  li {
                    a {
                      line-height: 35px;
                    }
                  }
                }
              }
            }
          }

          .navbar-header {
            height: 50px;

            .container {
              .head-center {
                .navbar-brand {
                  line-height: 50px;
                  height: 50px;
                }
              }

              .shop-menu {
                line-height: 50px;
                height: 50px;

                li {
                  line-height: 50px;
                  padding: 0;
                  margin: 0;

                  a {
                    line-height: 50px;
                    height: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .push {
    min-height: 50px;
  }
} /* End of view */
